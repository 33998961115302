.selwrapper{
width: 500px; 
display: flex; 
flex-direction: column;
padding:0px 20px 0px 20px;
}

.btn-fetch{
    /*padding: 2px 2px;*/
    border:solid;
    border-radius: 5px;
    border-width: thin;
    background-color:white;
  
}


.clsButtonWrap{
    float:right;
    background-color: aqua;
}

.topline {
    width: 100%;
    background-color: blueviolet;
}