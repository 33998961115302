.counters-iframe{
    border-width: 0px;
    border-style: none;
    border-color: none;
    border-image: none;
   /* overflow: visible;*/
    height: 950px;
}
.overlay{
   
    width:100%;
    overflow:visible;

    pointer-events: none;
    background:none !important;
    cursor: not-allowed;
    text-decoration: none;
    
}
.equipment-container{
    padding-top: 5px;
    height: 1000px; 



}
#testDiv{
    height: 300px;
    width: 1600px;
}